export const DialogValidateEvidencesConstants = {
  INCIDENCE: 'Incidencia',
  NO_IMAGE: 'Sin imagen',
  NO_INFO: 'Sin información',
  EVIDENCE_WRONG: 'La evidencia es errónea',
  EVIDENCE: 'Evidencia',
  FROM_GPS: ' del GPS',
  INVALID: 'Inválida',
  EVIDENCE_UPDATED: 'Evidence updated.',
  INVALID_STATUS: 'Invalida',
  VALID: 'Válida',
  VALID_STATUS: 'Valida',
  PENDING: 'Pendiente',
};

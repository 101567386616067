import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConstants } from '../../constants/app-constants.constants';
import { environment } from '../../../environments/environment';
import {
  EvidenceApiResponse,
  EvidenceReleaseBody,
  EvidencesSearchParams,
  FilePathParams,
  MultipleEvidencesApiResponse,
  OptionalParamsEvidenceSearch,
  ParamsEvidenceToSave,
  PathBuilderResponse
} from '../../interfaces';

const BASEURLV3 = environment.evidencesStorageUrl + environment.evidencesContainer;
const EVIDENCE_API_URL = environment.tmsEvidenceApiUrl;

@Injectable()

/**
 * Service to add all endpoints for evidence-api.
 */
export class EvidencesProviderService {
  /**
   * @description Evidence provider service.
   * @param { HttpClient } http - Service instance for http request.
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * @description Gets evidence by order object identifier.
   * @param { string } shipperOid - Current shipper unique identifier.
   * @param { string } orderOid - Order object identifier.
   * @param { OptionalParamsEvidenceSearch } optionalParams - Optional params to search an evidence.
   * @returns { EvidenceApiResponse } - Evidence found successfully.
   */
  public async getEvidenceByOrderOid(
    shipperOid: string,
    orderOid: string,
    optionalParams?: OptionalParamsEvidenceSearch
  ): Promise<EvidenceApiResponse> {
    let url = `${EVIDENCE_API_URL}/tenant/${shipperOid}/order/${orderOid}`;

    if (optionalParams) {
      for (const param in optionalParams) {
        url = url + `?${param}=${optionalParams[param]}`;
      }
    }

    return await this.http.get<EvidenceApiResponse>(url).toPromise();
  }

  /**
   * @description Retrieves all evidences from all orders evidenced in provided shipment.
   * @param {string} tenantId - Object id from tenant.
   * @param {string} shipmentId - Folio from shipment (EM).
   * @returns {Promise<EvidenceApiResponse>} Evidences from orders evidenced in shipment.
   */
  public async getEvidencesByMultipleParams(tenantId: string, searchParams: EvidencesSearchParams): Promise<MultipleEvidencesApiResponse> {
     return await this.http.post<MultipleEvidencesApiResponse>(`${EVIDENCE_API_URL}/tenant/${tenantId}/params`, searchParams).toPromise();
  }

  /**
   * @description Retrieves all evidences from all orders evidenced in provided shipment.
   * @param {string} tenantId - Object id from tenant.
   * @param {string} shipmentId - Folio from shipment (EM).
   * @param {boolean} shouldGetAllItems - Flag to validate if will retrieve all evidences items.
   * @returns {Promise<EvidenceApiResponse>} Evidences from orders evidenced in shipment.
   */
  public async getEvidencesByShipment(tenantId: string, shipmentId: string, shouldGetAllItems?: boolean): Promise<MultipleEvidencesApiResponse> {
    const query = shouldGetAllItems ? '?shouldGetAllItems=' + shouldGetAllItems : AppConstants.EMPTY_STRING;

    return await this.http.get<MultipleEvidencesApiResponse>(`${EVIDENCE_API_URL}/tenant/${tenantId}/shipment/${shipmentId}` + query).toPromise();
  }

  /**
   * @description Gets path files from the files passed.
   * @param { string } tenantOid - Current tenant/shipper identifier.
   * @param { Array<FilePathParams> } filesParams - Files params to get file path.
   * @returns { Array<PathBuilderResponse> } Path files built and success message.
   */
  public async getPathBuilderFiles(tenantOid: string, filesParams: Array<FilePathParams>): Promise<PathBuilderResponse> {
    const url = `${EVIDENCE_API_URL}/pathBuilder/tenant/${tenantOid}`;

    return await this.http.post<PathBuilderResponse>(url, filesParams).toPromise();
  }

  /**
   * @description Executes release of the orders, evidences, shipments and shipments requests (if applies).
   * @param {EvidenceReleaseBody} evidenceParams - Body with info from evidences, orders and shipments to release.
   * @param {string} tenantId - Object Id of tenant.
   * @returns {Promise<Response>} Response to client.
   */
  public async ordersEvidencesRelease(evidenceParams: EvidenceReleaseBody, tenantId: string): Promise<Response> {
    return await this.http.post<Response>(`${EVIDENCE_API_URL}/tenant/${tenantId}/evidenceRelease`, evidenceParams).toPromise();
  }

  /**
   * @description Saves evidence in the order selected.
   * @param { string } shipperOid - Current shipper unique identifier.
   * @param { ParamsEvidenceToSave } params - Params to save in the evidence.
   * @returns { EvidenceApiResponse } Evidence saved successfully.
   */
  public async saveEvidenceOrder(shipperOid: string, params: ParamsEvidenceToSave): Promise<EvidenceApiResponse> {
    const url = `${EVIDENCE_API_URL}/tenant/${shipperOid}/shipment/${params.shipmentId}/orderId/${params.orderOid}`;

    return await this.http.post<EvidenceApiResponse>(url, params).toPromise();
  }

  /**
   * @description Saves or updates evidences by multiple orders.
   * @param { string } shipperOid - Current shipper unique identifier.
   * @param { ParamsEvidenceToSave } params - Params to saves multiple evidences.
   * @returns { EvidenceApiResponse } Evidences saved successfully response.
   */
  public async saveMultipleEvidencesByOrder(shipperOid: string, params: ParamsEvidenceToSave): Promise<EvidenceApiResponse> {
    const shipmentId = params.shipmentId;
    const body: ParamsEvidenceToSave = {
      eventName: params.eventName ?? null,
      evidences: params.evidences,
      user: params.user ?? null
    };
    const url = `${EVIDENCE_API_URL}/tenant/${shipperOid}/shipment/${shipmentId}`;

    return await this.http.post<EvidenceApiResponse>(url, body).toPromise();
  }

  /**
   * @description Build Evidence URL.
   * @param {string} path - File path in Blob storage.
   * @param {string} fileName - Evidence File name.
   * @returns {string} Evidence File URl.
   */
  public buildEvidenceFileURL(path: string, fileName: string): string {
    return `${BASEURLV3}${path}${fileName}`;
  }

  /**
   * @description Remove evidence from the shipment.
   * @param {string} tenantOid - Tenant owner of evidence.
   * @param {string} evidenceOid - Evidence to remove.
   * @param {string} shipmentId - Shipment where evidence is located.
   * @param {File} file - File related to evidence.
   * @returns {Promise<EvidenceApiResponse>} Remove evidence response.
   */
  public async removeEvidence(tenantOid: string, evidenceOid: string, shipmentId: string, file: File): Promise<EvidenceApiResponse> {
    const fileName = file.name;
    const url = `${EVIDENCE_API_URL}/tenant/${tenantOid}/evidence/${evidenceOid}/shipment/${shipmentId}/file/${fileName}`;

    return await this.http.delete<EvidenceApiResponse>(url).toPromise();
  }
}

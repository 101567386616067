import { IMenuInterface } from '../interfaces/menu-interface';

export const MENU_ITEMS_MOCK: IMenuInterface[] = [
  {
    icon: 'home',
    title: 'Inicio',
    value: 'Inicio',
    routerLink: 'journey-menu/shipments',
    items: []
  },
  {
    icon: 'specific_settings',
    title: 'Configuración',
    value: 'Configuración',
    items: [
      {
        title: 'Embarcador',
        value: 'Embarcador',
        routerLink: 'biis/shipper-configuration'
      },
      {
        title: 'General',
        value: 'General',
        routerLink: 'configuration'
      },
      {
        title: 'Administrador de Notificaciones',
        value: 'Administrador de Notificaciones',
        routerLink: 'biis/manage-notifications'
      }
    ]
  },
  {
    icon: 'orders',
    title: 'Órdenes',
    value: 'Órdenes',
    items: [
      {
        title: 'Crear Orden',
        value: 'Crear Orden',
        routerLink: 'order/order-create'
      },
      {
        title: 'Ver Órdenes',
        value: 'Ver Órdenes',
        routerLink: 'order/view-orders'
      },
      {
        title: 'Carga Masiva de Órdenes',
        value: 'Carga Masiva de Órdenes',
        routerLink: 'order/order-massive-load'
      },
      {
        title: 'Recepción de Órdenes',
        value: 'Recepción de Órdenes',
        routerLink: 'order/order-reception'
      },
      {
        title: 'Pesos y volúmenes',
        value: 'Pesos y volúmenes',
        routerLink: 'order/order-detail-update'
      },
      {
        title: 'Pesos y volúmenes de forma masiva',
        value: 'Pesos y volúmenes de forma masiva',
        routerLink: 'order/order-detail-update-massive'
      },
      {
        title: 'Impresión de etiquetas',
        value: 'Impresión de etiquetas',
        routerLink: 'order/label-order-printing'
      },
      {
        title: 'Entrada de Mercancía',
        value: 'Entrada de Mercancía',
        routerLink: 'order/goods-receipt'
      },
      {
        routerLink: 'order/order-split',
        title: 'División de orden',
        value: 'División de orden'
      }
    ]
  },
  {
    icon: 'shipments',
    title: 'Embarques',
    value: 'Embarques',
    items: [
      {
        title: 'Planeación de Embarque',
        value: 'Planeación de Embarque',
        routerLink: 'shipment/shipment-creation'
      },
      {
        title: 'Ver Embarques',
        value: 'Ver Embarques',
        routerLink: 'shipment/shipment-view'
      },
      {
        title: 'Control Vehicular',
        value: 'Control Vehicular',
        routerLink: 'shipment/vehicle-control'
      },
      {
        title: 'Importar embarques de Roadnet',
        value: 'Importar embarques de Roadnet',
        routerLink: 'roadnet/shipment-import'
      },
      {
        title: 'Planeación con Roadnet',
        value: 'Planeación con Roadnet',
        routerLink: 'roadnet/order-routing'
      },
      {
        title: 'Impresión de etiquetas de paquetera',
        value: 'Impresión de etiquetas de paquetera',
        routerLink: 'shipment/label-courier-order-printing'
      },
      {
        title: 'Generar guías de paquetera',
        value: 'Generar guías de paquetera',
        routerLink: 'shipment/courier-waybill'
      },
      {
        title: 'Carga de Solicitudes Turísticas',
        value: 'Carga de Solicitudes Turísticas',
        routerLink: 'shipment/tourist-requests'
      },
      {
        title: 'Revertir Embarques',
        value: 'Revertir Embarques',
        routerLink: 'shipment/revert-shipment'
      },
      {
        title: 'Recolecciones',
        value: 'Recolecciones',
        routerLink: 'shipment/collection-request-view'
      },
      {
        title: 'Habilitar Reenvío de Timbrado',
        value: 'Habilitar Reenvío de Timbrado',
        routerLink: 'shipment/revert-failed-stamp'
      },
      {
        title: 'Carga no ejecutada',
        value: 'Carga no ejecutada',
        routerLink: 'shipment/load-not-executed'
      },
      {
        routerLink: 'shipment/remission-guides',
        title: 'Guías de remisión',
        value: 'Guías de remisión'
      },
      {
        title: 'Importar embarques de ruteador',
        value: 'Importar embarques de ruteador',
        routerLink: 'shipment/import-shipments-router'
      }
    ]
  },
  {
    icon: 'security',
    items: [
      {
        routerLink: 'shipment/vehicle-access-control',
        title: 'Control de Accesos de Unidades',
        value: 'Control de Accesos de Unidades'
      },
      {
        routerLink: 'shipment/vehicle-access-control-v2',
        title: 'Control de Acceso a Unidades',
        value: 'Control de Acceso a Unidades'
      }
    ],
    title: 'Seguridad',
    value: 'Seguridad',
  },
  {
    icon: 'shipment_request',
    title: 'Solicitudes',
    value: 'Solicitudes',
    items: [
      {
        title: 'Solicitudes Clientes',
        value: 'Solicitudes Clientes',
        routerLink: 'marketplace/customer-shipment-request'
      },
      {
        title: 'Solicitudes Embarcador',
        value: 'Solicitudes Embarcador',
        routerLink: 'biis/shipper-requests'
      },
      {
        title: 'Solicitudes Transportista',
        value: 'Solicitudes Transportista',
        routerLink: 'shipment/carrier-shipments'
      },
      {
        title: 'Bitácora de Solicitud',
        value: 'Bitácora de Solicitud',
        routerLink: 'biis/request-log'
      },
      {
        title: 'Histórico de Viajes',
        value: 'Histórico de Viajes',
        routerLink: 'biis/travel-history'
      },
      {
        title: 'Carga de Solicitudes con Órdenes',
        value: 'Carga de Solicitudes con Órdenes',
        routerLink: 'biis/request-load'
      },
      {
        title: 'Importación de Solicitudes con Órdenes',
        value: 'Importación de Solicitudes con Órdenes',
        routerLink: 'importation/shipments'
      },
      {
        title: 'Eventos de viaje',
        value: 'Eventos de viaje',
        routerLink: 'biis/shipmentEvents'
      },
      {
        title: 'Importación de Viajes para Hieleras',
        value: 'Importación de Viajes para Hieleras',
        routerLink: 'shipment/massive-load-shipments'
      },
      {
        title: 'Crear Solicitud de Transporte',
        value: 'Crear Solicitud de Transporte',
        routerLink: 'marketplace/new-shipment-request'
      }
    ]
  },
  {
    icon: 'tracking',
    title: 'Tracking',
    value: 'Tracking',
    routerLink: 'journey-menu',
  },
  {
    icon: 'evidences',
    title: 'Evidencias',
    value: 'Evidencias',
    items: [
      {
        title: 'Control de Evidencias',
        value: 'Control de Evidencias',
        routerLink: 'evidence/evidence-release'
      },
      {
        title: 'Crear folio de liberación de evidencias',
        value: 'Crear folio de liberación de evidencias',
        routerLink: 'evidence/evidence-release-folio'
      },
      {
        title: 'Consultar Folio de Liberación de Evidencia',
        value: 'Consultar Folio de Liberación de Evidencia',
        routerLink: 'evidence/evidence-release-folio-view'
      },
      {
        title: 'Descarga de Evidencias',
        value: 'Descarga de Evidencias',
        routerLink: 'evidence/evidence-download'
      },
      {
        title: 'Carga masiva de evidencias',
        value: 'Carga masiva de evidencias',
        routerLink: 'evidence/evidence-massive-load'
      },
      {
        title: 'Confirmación de entrega',
        value: 'Confirmación de entrega',
        routerLink: 'evidence/confirm-delivery-status'
      },
      {
        routerLink: 'evidence/evidence-control',
        title: 'Nuevo Control de Evidencias',
        value: 'Nuevo Control de Evidencias'
      },
      {
        routerLink: 'evidence/evidences-release',
        title: 'Liberación de órdenes',
        value: 'Liberación de órdenes'
      },
      {
        routerLink: 'evidence/documentary-register',
        title: 'Registro documental',
        value: 'Registro documental'
      }
    ]
  },
  {
    icon: 'cost',
    title: 'Costeo',
    value: 'Costeo',
    items: [
      {
        title: 'Carga Masiva de Tarifas',
        value: 'Carga Masiva de Tarifas',
        routerLink: 'cost/freight-rate-massive-load'
      },
      {
        title: 'Revisión de Cargos',
        value: 'Revisión de Cargos',
        routerLink: 'cost/cost-review'
      },
      {
        title: 'Sábana de Costeo',
        value: 'Sábana de Costeo',
        routerLink: 'cost/billing-sheet'
      },
      {
        title: 'Esquemas de Facturación',
        value: 'Esquemas de Facturación',
        routerLink: 'cost/account-billing-schemes-view'
      },
      {
        title: 'Propuesta de factura',
        value: 'Propuesta de factura',
        routerLink: 'cost/invoice-proposal'
      },
      {
        routerLink: 'cost/register-freight-invoices',
        title: 'Registro de facturas a sábanas',
        value: 'Registro de facturas a sábanas'
      },
      {
        title: 'Facturas de cliente',
        value: 'Facturas de cliente',
        routerLink: 'cost/customer-invoice'
      },
      {
        routerLink: 'cost/charge-validation',
        title: 'Validacion de cargos',
        value: 'Validacion de cargos'
      },
      {
        routerLink: 'cost/review-payment-supplier',
        title: 'Revisión de pago a proveedores',
        value: 'Revisión de pago a proveedores'
      },
      {
        routerLink: 'cost/review-customer-billing',
        title: 'Revisión de cobro a clientes',
        value: 'Revisión de cobro a clientes'
      },
      {
        routerLink: 'cost/sales-sheet',
        title: 'Sábana de costos de venta',
        value: 'Sábana de costos de venta'
      }
    ]
  },
  {
    icon: 'catalogs',
    title: 'Catálogos',
    value: 'Catálogos',
    items: [
      {
        title: 'Bodegas',
        value: 'Bodegas',
        routerLink: 'catalogs/warehouse/warehouse-view'
      },
      {
        title: 'Motivos de Traslado',
        value: 'Motivos de Traslado',
        routerLink: 'catalogs/transfer-reasons-view'
      },
      {
        title: 'Usuarios',
        value: 'Usuarios',
        routerLink: 'catalogs/user/user-view'
      },
      {
        title: 'Ubicaciones',
        value: 'Ubicaciones',
        routerLink: 'catalogs/location/location-view'
      },
      {
        title: 'Motivos de Rechazo',
        value: 'Motivos de Rechazo',
        routerLink: 'catalogs/rejection-reason/rejection-reason-view'
      },
      {
        title: 'Motivos de Incidencia',
        value: 'Motivos de Incidencia',
        routerLink: 'biis/incidence-catalog'
      },
      {
        title: 'Remolques',
        value: 'Remolques',
        routerLink: 'catalogs/trailer/trailer-view'
      },
      {
        title: 'Vehículos',
        value: 'Vehículos',
        routerLink: 'catalogs/vehicle/vehicle-view'
      },
      {
        title: 'Importación de Vehículos',
        value: 'Importación de Vehículos',
        routerLink: 'importation/vehicles'
      },
      {
        title: 'Operadores',
        value: 'Operadores',
        routerLink: 'catalogs/driver/driver-view'
      },
      {
        title: 'Tipos de Vehículos',
        value: 'Tipos de Vehículos',
        routerLink: 'catalogs/vehicle-type/vehicle-type-view'
      },
      {
        title: 'Tipos de Paquetes',
        value: 'Tipos de Paquetes',
        routerLink: 'catalogs/package/package-create'
      },
      {
        title: 'Líneas de Transporte',
        value: 'Líneas de Transporte',
        routerLink: 'catalogs/carrier/carrier-view'
      },
      {
        title: 'Motivos de Incidencia/Evidencia',
        value: 'Motivos de Incidencia/Evidencia',
        routerLink: 'catalogs/incidence-evidence/incidence-evidence-view'
      },
      {
        title: 'Cuentas',
        value: 'Cuentas',
        routerLink: 'catalogs/account/account-view'
      },
      {
        title: 'Embarcadores',
        value: 'Embarcadores',
        routerLink: 'catalogs/shipper-view'
      },
      {
        title: 'Roles',
        value: 'Roles',
        routerLink: 'catalogs/role/role-view'
      },
      {
        title: 'Tipos de Servicios',
        value: 'Tipos de Servicios',
        routerLink: 'catalogs/service-type/service-type-view'
      },
      {
        title: 'Receptores',
        value: 'Receptores',
        routerLink: 'catalogs/receiver/receiver-view'
      },
      {
        title: 'Tarifas de Licitación de transporte',
        value: 'Tarifas de Licitación de transporte',
        routerLink: 'catalogs/tarif/tarif-view'
      },
      {
        title: 'Grupos de tendering',
        value: 'Grupos de tendering',
        routerLink: 'catalogs/carrier-group/carrier-group-view'
      },
      {
        title: 'Productos',
        value: 'Productos',
        routerLink: 'catalogs/product/product-view'
      },
      {
        title: 'Cargos adicionales',
        value: 'Cargos adicionales',
        routerLink: 'catalogs/additional-charge-view'
      },
      {
        title: 'Motivos',
        value: 'Motivos',
        routerLink: 'catalogs/reason/reason-view'
      },
      {
        title: 'Catálogo de Tarifas de venta',
        value: 'Catálogo de Tarifas de venta',
        routerLink: 'catalogs/sales-rate/sales-rate-view'
      },
      {
        title: 'Proveedores',
        value: 'Proveedores',
        routerLink: 'catalogs/supplier/supplier-view'
      },
      {
        routerLink: 'catalogs/freight-rate',
        title: 'Catálogo de Tarifas de flete',
        value: 'Catálogo de Tarifas de flete',
      },
      {
        routerLink: 'catalogs/area/area-view',
        title: 'Catálogo de Zonas',
        value: 'Catálogo de Zonas'
      }
    ]
  },
  {
    icon: 'report',
    title: 'Reportes',
    value: 'Reportes',
    items: [
      {
        title: 'Control Tower',
        value: 'Control Tower',
        routerLink: 'reports/control-tower'
      },
      {
        title: 'Reporte en Tránsito',
        value: 'Reporte en Tránsito',
        routerLink: 'reports/on-route-report'
      },
      {
        title: 'Reporte de evidencias',
        value: 'Reporte de evidencias',
        routerLink: 'reports/evidences/evidences'
      },
      {
        title: 'Histórico de Temperaturas',
        value: 'Histórico de Temperaturas',
        routerLink: 'reports/temperature-summary/temperature-summary'
      },
      {
        title: 'Reporte Maestro Costeo',
        value: 'Reporte Maestro Costeo',
        routerLink: 'reports/master-costing-report'
      },
      {
        title: 'Reporte Pendientes de Embarque',
        value: 'Reporte Pendientes de Embarque',
        routerLink: 'reports/shipment-pending-report'
      },
      {
        title: 'Reporte de Eficiencia de Operación',
        value: 'Reporte de Eficiencia de Operación',
        routerLink: 'reports/operating-efficiency-report'
      },
      {
        title: 'Reporte de Incidencias de Rechazo',
        value: 'Reporte de Incidencias de Rechazo',
        routerLink: 'reports/incidence-report'
      },
      {
        title: 'Reporte de Pendientes de Costeo',
        value: 'Reporte de Pendientes de Costeo',
        routerLink: 'reports/pending-freight-cost'
      },
      {
        title: 'Reporte de Estatus General de Órdenes',
        value: 'Reporte de Estatus General de Órdenes',
        routerLink: 'reports/general-order-status-report'
      },
      {
        title: 'Reporte de Pendiente de Evidencias',
        value: 'Reporte de Pendiente de Evidencias',
        routerLink: 'reports/pending-evidences/pending-evidences'
      },
      {
        title: 'Reporte de sábanas de costeo',
        value: 'Reporte de sábanas de costeo',
        routerLink: 'reports/costing-sheets-report'
      }
    ]
  },
  {
    icon: 'bulk',
    title: 'Movimientos masivos',
    value: 'Movimientos masivos',
    routerLink: 'massive-movements/massive-movements'
  },
  {
    icon: 'support-help',
    items: [
      {
        routerLink: 'support/status-changes',
        title: 'Cambios de estatus',
        value: 'Cambios de estatus'
      }
    ],
    title: 'Soporte',
    value: 'Soporte'
  }
];

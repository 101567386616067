import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { GetOrderGuideStatusBody,
  GetRemissionFoliosParams,
  GetRemissionGuidesResponse,
  GetRemissionGuideStatusResponse,
  OrderDataGetAuthorization
} from '../../interfaces/remission-guides';

const API_URL = environment.apiUrl;
const ORDER_API_URL = environment.orderApiUrl;

@Injectable()

/**
 * Service for remission guides module with call to endpoints relatred with remission guides.
 */
export class RemissionGuidesProviderService {
  /**
   * @description Remission guides provider service.
   * @param { HttpClient } http - Service instance for http request.
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * @description Send orders data with remission guides info to be authorized.
   * @param {string} tenantId - OId from tenant.
   * @param {Array<OrderDataGetAuthorization>} data - All data from orders, included remission guides for each one to be authorized.
   * @returns {Promise<void>}
   */
  public async getAutorizationForRemissionGuides(tenantId: string, data: Array<OrderDataGetAuthorization>): Promise<void> {
    return await this.http.post<void>(`${API_URL}/tenant/${tenantId}/remissionGuides`, data).toPromise();
  }

  /**
   * @description Executes a request to retrieve current status from provided remission guide.
   * @param {GetOrderGuideStatusBody} body - Contains data to excute the query and retrieve status for provided guide remission.
   * @param {string} tenantOId - OId from tenant is executing request.
   * @returns {Promise<GetRemissionGuideStatusResponse>} - Response from integrator with status data about remission guide requested.
   */
  public async getRemissionGuideStatus(body: GetOrderGuideStatusBody, tenantOId: string): Promise<GetRemissionGuideStatusResponse> {
    return await this.http.post<GetRemissionGuideStatusResponse>(`${API_URL}/tenant/${tenantOId}/remissionGuides/status`, body).toPromise();
  }

  /**
   * @description Builds and sets new remission folios for orders in shipments, emitterPoints and establishments provideds.
   * @param {GetRemissionFoliosParams} params - Contains necessary info to execute the request like tenantOid, username, etc).
   * @returns {Promise<GetRemissionGuidesResponse>} - Response with new folios created for orders provided.
   */
  public async getRemissionGuidesFolios(params: GetRemissionFoliosParams): Promise<GetRemissionGuidesResponse> {
    const body = {
      creationDate: new Date(),
      data: params.body
    };

    return await this.http.post<GetRemissionGuidesResponse>(`${ORDER_API_URL}/tenantId/${params.tenantOId}/user/${params.user}/` +
      'remissionGuides', body).toPromise();
  }
}

/**
 * @description Available status can belong a remission guide after receive response from being sent to authorization.
 */
export enum RemissionGuidesAuthorizationStatus {
  authorizated = 'authorizated',
  inValidation = 'inValidation',
  noData = 'noData',
  noGenerated = 'noGenerated',
  rejected = 'rejected',
  returned = 'returned'
}

/**
 * @description All possible ID responses from Tandicorp of available status from guide.
 */
export const REMISSION_GUIDE_TANDICORP_STATUS = {
  authorizated: 'AUTORIZADO',
  inValidation: 'ENVIADO',
  noData: 'NO_RESPUESTA',
  rejected: 'RECHAZADO',
  returned: 'REENVIADO'
};

/**
 * @description Status from remission guides can't send to authorization yet.
 */
export const REMISSION_GUIDES_UNAUTHORIZED_STATUS = [
  'authorizated',
  'inValidation',
  'noData'
];

export const TRANSFER_REASONS_CREATE_CONST = {
  cancelIcon: '../../../assets/eliminate.svg',
  dialogWidth: '650px',
  editIcon: '../../../assets/cedis_edition_dialog.svg',
  errorSeparator: ' - ',
  replaceIndicator: '{field}',
  supplierUniqueFields: [
    'identifier',
    'name',
    'shortName',
    'rfc'
  ],
  supplierViewRoute: 'catalogs/supplier/supplier-view',
  uniqueFieldError: 'UNIQUE_SUPPLIER_FIELD',
  iconColor: 'resume-mat-info-icon',
};

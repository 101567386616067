import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Vehicle, VehicleResponse, VehicleSchema, VehiclesParams } from '../../interfaces';
import { VehicleImportation } from '../../pages/catalogs/vehicle/import-vehicles/vehicle-importation';

const apiUrl = environment.apiUrl;

@Injectable()
export class VehicleProvider {
  constructor(private http: HttpClient) {}

  /**
   * @description get all available Carrier Vehicles
   */
  public async getCarrierVehicles(carrierOid: string): Promise<Array<Vehicle>> {
    return await this.http.get<Array<Vehicle>>(`${apiUrl}/lineaTransporte/${carrierOid}/vehiculos`)
      .toPromise();
  }

  private returnCoreBody(vehicleBody: VehicleSchema): Object {
    const coreBody = {
      ejes: vehicleBody.axle,
      color: vehicleBody.color,
      placas: vehicleBody.plates,
      modelo: vehicleBody.model,
      anio: vehicleBody.year,
      max_volumen: vehicleBody.maxVolume,
      max_peso: vehicleBody.maxWeigth,
      max_pallet: vehicleBody.maxPallets,
      kmTraveled: vehicleBody.kmTraveled,
      tipo: vehicleBody.kind._id,
      driver: undefined,
      sctPermitType: vehicleBody.sctPermitType,
      sctPermitNumber: vehicleBody.sctPermitNumber,
      insuranceCarrierName: vehicleBody.insuranceCarrierName,
      insurancePolicyNumber: vehicleBody.insurancePolicyNumber,
      vehicleConfiguration: vehicleBody.vehicleConfiguration,
      doubleDeckInfo: vehicleBody.doubleDeckInfo
    };
    if (vehicleBody.driver) {
      coreBody.driver = vehicleBody.driver;
    }
    return coreBody;
  }

  /**
   * @description send a POST function to create a vehicle
   * @params {carrierOid, vehicleBody}
   */
  public async createCarrierVehicle(carrierOid: string, vehicleBody: VehicleSchema): Promise<object> {
    const coreBody = this.returnCoreBody(vehicleBody);
    return await this.http.post<Object>(`${apiUrl}/lineaTransporte/${carrierOid}/vehiculos`, coreBody).toPromise();
  }
  /**
   * @description send a vehicle to edit
   * @params {carrierOid, vehicleOid, vehicleBody}
   */
  public async editCarrierVehicle(carrierOid: string, vehicleOid: string, vehicleBody: VehicleSchema): Promise<object> {
    const coreBody = this.returnCoreBody(vehicleBody);
    return await this.http.put<Object>(`${apiUrl}/lineaTransporte/${carrierOid}/vehiculos/${vehicleOid}`, coreBody)
      .toPromise();
  }
  /**
   * @description send a vehicle to delete
   * @params {carrierOid, vehicleOid}
   */
  public async deleteCarrierVehicle(carrierOid: string, vehicleOid: string): Promise<object> {
    return await this.http.delete(`${apiUrl}/lineaTransporte/${carrierOid}/vehiculos/${vehicleOid}`)
      .toPromise();
  }
 /**
 * @description Massive load of vehicles
 * @params {vehicles}
 */
  public async massiveLoadVehicles(vehicles: Array<VehicleImportation>): Promise<string> {
    const body = {
      vehicles
    };
    return await this.http.post<string>(`${apiUrl}/vehicles/massiveLoads`, body).toPromise();
  }

  /**
  * @description Get vehicle by vehicleId
  * @param carrierOid Carrier Object Id
  * @param vehicleOid Vehicle Object Id
  * @returns {Promise<vehicle>} Shipper objects Ids
  **/
  public async getVehicleByOid(carrierOid: string, vehicleOid: string): Promise<Vehicle> {
    return await this.http.get<Vehicle>(`${apiUrl}/lineaTransporte/${carrierOid}/vehiculos/${vehicleOid}`).toPromise();
  }

  /**
   * @description Get vehicle by plate
   * @param {string} plate Vehicle plate as id
   * @param {string} carrierOid Current carrier Object ID
   * @returns {Promise<VehicleResponse>} Shipper objects Ids
   */
  public async getVehicleByPlate(plate: string, carrierOid: string): Promise<VehicleResponse> {
    return await this.http.get<VehicleResponse>(`${apiUrl}/vehicles/${plate}/carrier/${carrierOid}`).toPromise();
  }

  /**
   * @description Updates vehicle status.
   * @param {string} shipperOid - Current tenant/shipper identifier.
   * @param {VehiclesParams} vehicleParams - Vehicles params to release vehicle.
   * @returns {VehicleResponse} Success message or error.
   */
  public async updateVehicleStatus(shipperOid: string, vehicleParams: VehiclesParams): Promise<VehicleResponse> {
    const url = `${apiUrl}/shipper/${shipperOid}/vehicleStatus`;

    return await this.http.put<VehicleResponse>(url, vehicleParams).toPromise();
  }
}

export const ORDER_SCANNED_STATUS = [
  { value: 'Completa', label: 'complete' },
  { value: 'Incompleta', label: 'incomplete' },
];

export const ORDER_SEARCH_TYPES = [
  { value: 'Folio', label: 'folio' },
  { value: 'Identificador', label: 'identifier' },
  { value: 'Referencia Interna', label: 'internalReference' },
  { value: 'Factura', label: 'invoice' }
];

export const ORDER_STATUS = [
  { value: 'En Tránsito', label: 'onDelivery' },
  { value: 'Entregada', label: 'delivered' },
  { value: 'Asignada', label: 'assigned' },
  { value: 'Sin Asignar', label: 'unassigned' },
  { value: 'No Entregada', label: 'undelivered' },
  { value: 'Liberada', label: 'released' },
  { value: 'Rechazada', label: 'rejected' },
  { value: 'Parcial', label: 'partial' },
  { value: 'Cancelada', label: 'cancelled' },
  { value: 'Sin Evidencia', label: 'noEvidence' }
];

export const ORDERS_CREATION_ERRORS = {
  accountNotFound: 'The provided account could not be found, please submit a valid account',
  accountRequired: 'Account field is required',
  boxesRequired: 'Boxes field is required',
  deliveryDateIsExpired: 'The provided deliveryDate is not valid because is before current day, please provide a valid date',
  deliveryDateRequired: 'Delivery date field is required',
  destAddressReq: 'Destination address field is required',
  destLatitudeReq: 'Destination latitude is required',
  destLongitudeReq: 'Destination longitude is required',
  destMunicipalityReq: 'Destination municipality field is required',
  destNameReq: 'Destination name field is required',
  destPCReq: 'Destination postalCode field is required',
  destSettlementReq: 'Destination settlement is required',
  destStateReq: 'Destination state field is required',
  destinationIdReq: 'Destination identifier field is required',
  destinationNotFound: 'The provided identifier destination could not be found, please submit a valid destination',
  duplicatedOrderId: 'Order identifier already exists on data base',
  errorDuplicatedId: 'The following identifiers are duplicated at least once',
  identifierRequired: 'Order identifier is required',
  invalidAccount: 'The provided account is not valid, please submit a valid account value',
  invalidActIngManufactCountNameValue: 'Invalid activeIngredientManufacturerCountryName field for product',
  invalidActIngMaqCountNameValue: 'Invalid activeIngredientMaquilaCountryName field for product',
  invalidActIngNameValue: 'Invalid activeIngredientName field for product',
  invalidActSubsGenerValue: 'Invalid activeSubstanceGeneric field for product',
  invalidAssemblyFoorprintName: 'Invalid footprintName value for product',
  invalidAssemblyHeight: 'Invalid height value for product',
  invalidAssemblyLength: 'Invalid length value for product',
  invalidAssemblyWidth: 'Invalid width value for product',
  invalidAuthUseValue: 'Invalid authorizedUse field for product',
  invalidBoxes: 'The provided boxes quantity is not valid, please submit a valid boxes quantity',
  invalidChemNameValue: 'Invalid chemicalName field for product',
  invalidCofPestNumbValue: 'Invalid cofeprisPesticideNumber field for product',
  invalidCofSectorValue: 'Invalid COFEPRIS SECTOR for product',
  invalidCustpickupWithReceiver: 'The field customerPickup cant be true if receiver is provided',
  invalidCustpickupWithRecpickup: 'The field customerPickup cant be true if receptorPickup field is true',
  invalidDangMatkey: 'Invalid dangerousMaterialKey value for product',
  invalidDeliveryDate: 'Delivery date format is incorrect, the correct format must be DD/MM/YYYY',
  invalidDeliveryHour: 'Delivery time format is incorrect, the correct format must be HH:mm',
  invalidDestAddress: 'The provided destination address is invalid, please provide a valid destination address',
  invalidDestContact: 'The provided destination contact is invalid, please provide a valid destination contact',
  invalidDestMunicipality: 'The provided destination municipality is invalid, please provide a valid destination municipality',
  invalidDestName: 'The provided destination name is invalid, please provide a valid destination name',
  invalidDestNumber: 'The provided destination number is invalid, please provide a valid destination number',
  invalidDestPC: 'The provided destination postalCode is invalid, please provide a valid destination postalCode',
  invalidDestState: 'The provided destination state is invalid, please provide a valid destination state',
  invalidDestinationIdentifier: 'The provided destination identifier is invalid, please provide a valid destination identifier',
  invalidGrouper: 'The provided order grouper is not valid, please provide a valid order grouper',
  invalidHealthRegFolValue: 'Invalid healthRegisterFolio field for product',
  invalidIdentifier: 'The provided identifier is invalid, please submit a valid identifier',
  invalidImpCompanyValue: 'Invalid importCompany field for product',
  invalidImpLicenseValue: 'Invalid importLicense field for product',
  invalidImpVucFolValue: 'Invalid importVUCEMFolio field for product',
  invalidIntRef: 'The provided internal reference is invalid, please provide a valid internal reference',
  invalidInvoice: 'The provided invoice field is not valid, please provide a valid invoice value',
  invalidIsCofProdValue: 'Invalid isCofeprisProduct value for product',
  invalidLengthInternalReference: 'The internal reference must have at least 3 characters and maximum of 50 characters',
  invalidLengthInvoice: 'The invoice must have at least 3 characters and maximum of 30 characters',
  invalidManufacValue: 'Invalid manufacturer field for product',
  invalidMatterTypeValue: 'Invalid matterType value for product',
  invalidMinLengthIdentifier: 'The minimum length for identifier is 3 characters',
  invalidMinLengthInternalReference: 'The minimum length for internal reference is 3 characters',
  invalidMinLengthOrderGrouper: 'The minimum length for order grouper is 3 characters',
  invalidNumCasValue: 'Invalid numCAS field for product',
  invalidOrderDestination: 'The provided order destination cant be assigned to the provided receiver',
  invalidOrderType: 'The provided order type is not valid, please submit a valid order type',
  invalidOriginName: 'The provided origin name is invalid, please submit a valid origin name',
  invalidPallets: 'The provided pallets quantity is not valid, please submit a valid pallets quantity',
  invalidPestManufactCountNameValue: 'Invalid pesticideManufacturerCountryName field for product',
  invalidPharmFormValue: 'Invalid pharmaceuticalForm field for product',
  invalidPieces: 'The provided pieces quantity is not valid, please submit a valid pieces quantity',
  invalidProductCatalog: 'The provided product doesnt exist',
  invalidProductCode: 'Invalid code value for product',
  invalidProductForeignTrade: 'Invalid foreignTradeUuid value for product',
  invalidProductName: 'Invalid name value for product',
  invalidProductPackaging: 'Invalid packaging value for product',
  invalidProductPackagingDesc: 'Invalid packagingDescription value for product',
  invalidProductPrice: 'Invalid price value for product',
  invalidProductSerKey: 'Invalid productServiceKey value for product',
  invalidProductTariffFrac: 'Invalid tariffFraction value for product',
  invalidProductTotal: 'Invalid total quantity for product',
  invalidProductUnit: 'Invalid unit value for product',
  invalidProductUnitKey: 'Invalid unitKey value for product',
  invalidExpDateFormatValue: 'Invalid date format (YYYY-MM-DD) for product',
  invalidLotValue: 'Invalid lot field for product',
  invalidMinExpDateValue: 'expirationDate is invalid because is before current day, please provide a valid expirationDate for product',
  invalidRcpCodeWithRecpickup: 'The field receptorCode is required when receptorPickup field is true',
  invalidReceptorCode: 'The provided receptorCode is invalid, please provide a valid receptorCode value',
  invalidServiceType: 'The provided serviceType is not valid, please provide a valid serviceType',
  invalidTradeMedValue: 'Invalid trademarkMedication field for product',
  invalidTransEspConValue: 'Invalid transportSpecialConditions field for product',
  invalidVolume: 'The provided volume is not valid, please submit a valid volume value',
  invalidVolumeProd: 'Invalid volume value for product',
  invalidWeight: 'The provided weight is not valid, please submit a valid weight value',
  invalidWeightProd: 'Invalid weight value for product',
  orderTypeRequired: 'Order type field is required',
  originNameRequired: 'Origin name field is required',
  originNotFound: 'The provided origin name could not be found, please submit a valid origin name',
  palletsRequired: 'Pallets field is required',
  piecesRequired: 'Pieces field is required',
  prodErrorNocode: 'product(s) requires the code field',
  receiverNotFound: 'The provided receiver could not be found, please submit a valid receiver',
  reqDangMatkey: 'dangerousMaterialKey is required for product',
  reqPackaging: 'packaging is required for product',
  reqPackagingDesc: 'packagingDescription is required for product',
  requiredExpirationDate: 'expirationDate field is required because the cofeprisSector value is equal to 01, 02, or 03, for product',
  requiredPbp: 'It is required that at least one of the pieces, boxes or pallets fields have a value greater than 0',
  serviceTypeNotFound: 'The provided serviceType could not be found, please provide an existing serviceType',
  volumeRequired: 'Volume field is required',
  weightRequired: 'Weight field is required'
};

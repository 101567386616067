import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from '../../app.service';
import { Area, AreaRequestBody, AreaResponse, AreaSearchRequest } from '../../interfaces/area';
import { environment } from '../../../environments/environment';

const URL_BASE = environment.apiUrl;

/**
 * @description Service to make requests for areas information.
 */
@Injectable()
export class AreaProvider {
  /**
   * @description Constructor for TmsRouterProvider.
   * @param {HttpClient} http - HTTP client for making requests.
   * @param {AppService} appService - Provides necessary information about the authenticated tenant in the system.
   */
  constructor(private http: HttpClient, private appService: AppService) { }

  /**
   * @description Creates a new area for a specific tenant.
   * @param {string} tenantOid - The unique identifier of the tenant.
   * @param {string} userOid - The current user object id.
   * @param {AreaRequestBody} bodyData - The data to create the new area, including identifier and name.
   * @returns {Promise<AreaResponse>} The response containing the created areas data.
   */
  public async create(tenantOid: string, userOid: string, bodyData: AreaRequestBody): Promise<AreaResponse> {
    return this.http.post<AreaResponse>(`${URL_BASE}/tenant/${tenantOid}/user/${userOid}/areas`, bodyData).toPromise();
  }

  /**
   * @description Retrieves an area by its identifier for a specific tenant.
   * @param {string} tenantOid - The unique identifier of the tenant.
   * @param {string} userOid - The current user object id.
   * @param {string} identifier - The identifier of the area to retrieve.
   * @returns {Promise<AreaResponse>} The area data response.
   */
  public async getAreaByIdentifier(tenantOid: string, userOid: string, identifier: string): Promise<AreaResponse> {
    return this.http.get<AreaResponse>(`${URL_BASE}/tenant/${tenantOid}/user/${userOid}/areas/identifier/${identifier}`).toPromise();
  }

  /**
   * @description Retrieves an area by its name for a specific tenant.
   * @param {string} tenantOid - The unique identifier of the tenant.
   * @param {string} userOid - The current user object id.
   * @param {string} name - The name of the area to retrieve.
   * @returns {Promise<AreaResponse>} The area data response.
   */
  public async getAreaByName(tenantOid: string, userOid: string, name: string): Promise<AreaResponse> {
    return this.http.get<AreaResponse>(`${URL_BASE}/tenant/${tenantOid}/user/${userOid}/areas/name/${name}`).toPromise();
  }

  /**
   * @description Retrieves an area by its priority for a specific tenant.
   * @param {string} tenantOid - The unique identifier of the tenant.
   * @param {string} userOid - The current user object id.
   * @param {string} priority - The name of the area to retrieve.
   * @returns {Promise<AreaResponse>} The area data response.
   */
  public async getAreaByPriority(tenantOid: string, userOid: string, priority: number): Promise<AreaResponse> {
    return this.http.get<AreaResponse>(`${URL_BASE}/tenant/${tenantOid}/user/${userOid}/areas/priority/${priority}`).toPromise();
  }

  /**
   * @description Gets paginated items from the backend.
   * @param {AreaSearchRequest} searchBody - The search criteria.
   * @param {number} page - The page number.
   * @param {number} limit - The maximum number of items per page.
   * @returns {Promise<AreaResponse>} - The paginated areas.
   */
  public async paginate(searchBody: AreaSearchRequest, page: number, limit: number): Promise<AreaResponse> {
    const url = URL_BASE + `/tenant/${searchBody.tenantOid}/user/${searchBody.userOid}/areas?page=${page}&limit=${limit}&sortBy=createdDate&criteriaText=${searchBody.criteriaText}`;

    return this.http.get<AreaResponse>(url).toPromise();
  }

  /**
   * @description Creates a new area for a specific tenant.
   * @param {string} tenantOid - The unique identifier of the tenant.
   * @param {string} userOid - The current user object id.
   * @param {Area} bodyData - The data to update the new area, including identifier and name.
   * @returns {Promise<AreaResponse>} The response containing the created areas data.
   */
  public async update(tenantOid: string, userOid: string, bodyData: Area): Promise<AreaResponse> {
    return this.http.put<AreaResponse>(`${URL_BASE}/tenant/${tenantOid}/user/${userOid}/areas/${bodyData._id}`, bodyData).toPromise();
  }
}

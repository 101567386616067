import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { SearchTranferReasonsByParams,
  TransferReasons,
  TransferReasonsRequestParams,
  TransferReasonsResponse
} from '../../interfaces/transferReasons';

const apiUrl = environment.apiUrl;

@Injectable({ providedIn: 'root' })

/**
 * @description Service to make request for suppliers.
 */
export class TransferReasonsService {
  /**
   * @description Builds an instance of all the services necessary for this component to work correctly.
   * @param {HttpClient} http - To make http request.
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * @description Gets reasons for transfer by shipperOId.
   * @param {TransferReasonsRequestParams} params - The request parameters.
   * @returns {Promise<TransferReasons>} - A promise of reasons for transfer.
   */
  public async getReasonsByShipperOId(shipperOId): Promise<TransferReasonsResponse> {
    return this.http.get<TransferReasonsResponse>(`${apiUrl}/transferReasons/shipperOId/${shipperOId}`).toPromise();
  }

  /**
   * @description Creates a new transfer reasons.
   * @param {TransferReasonsRequestParams} transferReasons - The transfer reasons to create.
   * @returns {Promise<TransferReasons>} - A promise of the created transfer reasons.
   */
  public async createNewTransferReasons(transferReasons: TransferReasonsRequestParams): Promise<TransferReasons> {
    return this.http.post<TransferReasons>(`${apiUrl}/transferReasons/shipperOId/${transferReasons.shipperOId}`, transferReasons.transferReasonsBody).toPromise();
  }

  /**
   * @description Updates an existing transfer reasons.
   * @param {TransferReasonsRequestParams} transferReasons - The transfer reasons to update.
   * @returns {Promise<TransferReasons>} - A promise of the updated transfer reasons.
   */
  public async updateTransferReasons(transferReasons: TransferReasonsRequestParams): Promise<TransferReasons> {
    return this.http.put<TransferReasons>(`${apiUrl}/transferReasons/shipperOId/${transferReasons.shipperOId}`, transferReasons.transferReasonsBody).toPromise();
  }

  /**
   * @description Deletes a transfer reasons by transferReasonsOid.
   * @param {TransferReasonsRequestParams} params - The OID of the transfer reasons to delete.
   */
  public async deleteTransferReasons(params: TransferReasonsRequestParams): Promise<void> {
    return this.http.delete<void>(`${apiUrl}/transferReasons/${params.transferReasonsOId}/userName/${params.userName}`).toPromise();
  }

  /**
   * @description Searchs transfer reasons by provided params.
   * @param {SearchTranferReasonsByParams} body - Params ny transfer reasons will search.
   * @returns {Promise<TransferReasonsResponse>} - Transfer reasons found for provided params.
   */
  public async searchTransferReasonsByParams(body: SearchTranferReasonsByParams): Promise<TransferReasonsResponse> {
    return this.http.post<TransferReasonsResponse>(`${apiUrl}/transferReasons/byParams`, body).toPromise();
  }
}

import { ImportationCategory } from '../../../../interfaces';

export const PRODUCT_INFO_FORM: ImportationCategory = {
  name: 'productInfo',
  rules: [
    {
      name: 'productName',
      required: true
    },
    {
      name: 'productCode',
      required: true
    },
    {
      name: 'productTotal',
      required: true
    },
    {
      name: 'productLot',
      required: false
    },
    {
      name: 'productExpirationDate',
      required: false
    },
    {
      name: 'productUnit',
      required: false
    },
    {
      name: 'productUnitKey',
      required: true
    },
    {
      name: 'productProdServKey',
      required: false
    },
    {
      name: 'productWeight',
      required: true
    },
    {
      name: 'productPrice',
      required: false
    },
    {
      name: 'productCmm',
      required: false
    },
    {
      name: 'productIsDangerousMaterial',
      required: true
    },
    {
      name: 'productDangerousMaterialKey',
      required: false
    },
    {
      name: 'productPackaging',
      required: false
    },
    {
      name: 'productPackagingDescription',
      required: false
    },
    {
      name: 'productTariffFraction',
      required: false
    },
    {
      name: 'productForeignTradeUuid',
      required: false
    },
    {
      name: 'productFootprintName',
      required: true
    },
    {
      name: 'productWidth',
      required: true
    },
    {
      name: 'productLength',
      required: true
    },
    {
      name: 'productHeight',
      required: true
    },
    {
      name: 'productPosition',
      required: false
    },
    {
      name: 'productPerspective',
      required: false
    }
  ]
};

export const INPUT_MIN_MAX = {
  address: {
    maxLength: 100,
    minLength: 3
  },
  barcode: { maxLength: 25 },
  brand: { maxLength: 25 },
  chassis: { maxLength: 25 },
  color: { maxLength: 25 },
  contactName: {
    maxLength: 50,
    minLength: 3
  },
  deliveryClass: {
    maxLength: 10,
    minLength: 3
  },
  engineNumber: { maxLength: 25 },
  footprintName: {
    maxLength: 50,
    minLength: 3
  },
  identifier: {
    maxLength: 30,
    minLength: 3
  },
  maternalSurname: {
    maxLength: 50,
    minLength: 3
  },
  mobileNumber: {
    maxLength: 25,
    minLength: 8
  },
  orderDeliveryDoc: { maxLength: 8 },
  orderDeliveryNum: { maxLength: 10 },
  orderDoc: { maxLength: 8 },
  orderNum: { maxLength: 10 },
  originCountry: { maxLength: 25 },
  paternalSurname: {
    maxLength: 50,
    minLength: 3
  },
  phoneNumber: {
    maxLength: 25,
    minLength: 8
  },
  productHeight: { minLength: 1 },
  productLength: { minLength: 1 },
  productWidth: { minLength: 1 },
  productionYear: { maxLength: 25 },
  transferReasons: {
    maxLength: 100,
    minLength: 3
  },
  rfc: {
    maxLength: 13,
    minLength: 12
  },
  series: { maxLength: 25 },
  shortName: {
    maxLength: 50,
    minLength: 3
  },
  supplierName: {
    maxLength: 100,
    minLength: 3
  }
};

<div class="general-container">
  <h3 class="title-page" *ngIf="transferReasonsToEdit">{{ 'transferReasonsCreateLabels.dialogEditTitle' | translate }}</h3>
  <h3 class="title-page" *ngIf="!transferReasonsToEdit">{{ 'transferReasonsCreateLabels.title' | translate }}</h3>

  <!-- Inputs section -->
  <div class="inputs-section">
    <form [formGroup]="transferReasonsForm">

      <mat-grid-list cols="2" rowHeight="1:.30">
        <mat-grid-tile>
          <mat-form-field>
            <input
              id="trcc-input-identifier"
              (input)="onIdentifierChange()"
              [readonly]="isReadOnly"
              [ngClass]="{'visual-disabled-class': isReadOnly}"
              required
              matInput
              formControlName="identifier"
              placeholder="{{ 'transferReasonsCreateLabels.identifier' | translate }}">
              <mat-error *ngIf="transferReasonsForm.get('identifier').hasError('required')">
                <strong>{{ 'transferReasonsCreateLabels.identifierRequired' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="transferReasonsForm.get('identifier').hasError('minlength')">
                <strong>{{ 'transferReasonsCreateLabels.identifierMinLength' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="transferReasonsForm.controls['identifier'].hasError('pattern')">
                <strong>{{ 'transferReasonsCreateLabels.identifierPattern' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="transferReasonsForm.get('identifier').hasError('maxlength')">
                <strong>{{ 'transferReasonsCreateLabels.identifierMaxLength' | translate }}</strong>
              </mat-error>
              <p *ngIf="hasIdentifierInReasonsList" class="error-input">
                {{ 'transferReasonsCreateLabels.duplicatedIdentifier' | translate }}
              </p>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field>
            <input
              id="trcc-input-delivery-type"
              (input)="onDeliveryTypeChange()"
              required
              matInput
              formControlName="deliveryClass"
              placeholder="{{ 'transferReasonsCreateLabels.deliveryClass' | translate }}">
              <mat-error *ngIf="transferReasonsForm.get('deliveryClass').hasError('required')">
                <strong>{{ 'transferReasonsCreateLabels.deliveryTypeIsRequired' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="transferReasonsForm.get('deliveryClass').hasError('minlength')">
                <strong>{{ 'transferReasonsCreateLabels.deliveryTypeMinLength' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="transferReasonsForm.get('deliveryClass').hasError('maxlength')">
                <strong>{{ 'transferReasonsCreateLabels.deliveryTypeMaxLength' | translate }}</strong>
              </mat-error>
              <p *ngIf="hasDeliveryTypeInReasonsList" class="error-input">
                {{ 'transferReasonsCreateLabels.deliveryTypeAlreadyExists' | translate }}
              </p>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field>
            <input
              id="trcc-input-transfer-reasons"
              required
              matInput
              formControlName="transferReasons"
              placeholder="{{ 'transferReasonsCreateLabels.transferReasons' | translate }}">
              <mat-error *ngIf="transferReasonsForm.get('transferReasons').hasError('required')">
                <strong>{{ 'transferReasonsCreateLabels.transferReasonsIsRequired' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="transferReasonsForm.get('transferReasons').hasError('minlength')">
                <strong>{{ 'transferReasonsCreateLabels.transferReasonsMinLength' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="transferReasonsForm.get('transferReasons').hasError('maxlength')">
                <strong>{{ 'transferReasonsCreateLabels.transferReasonsMaxLength' | translate }}</strong>
              </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="buttons-section">
            <button
              (click)="onCancel()"
              id="trcc-transfer-reasons-cancel-button"
              mat-stroked-button
              color="primary"
              class="secondary-button">
              {{ 'transferReasonsCreateLabels.cancel' | translate }}
            </button>
            <button
              *ngIf="!transferReasonsToEdit"
              (click)="onConfirm()"
              [disabled]="shouldDisabledSaveButton()"
              id="trcc-transfer-reasons-create-button"
              mat-raised-button
              color="primary"
              class="primary-button">
              {{ 'transferReasonsCreateLabels.create' | translate }}
            </button>
            <button
              *ngIf="transferReasonsToEdit"
              (click)="showConfirmEditDialog()"
              [disabled]="shouldDisabledEditButton()"
              id="trcc-transfer-reasons-save-button"
              mat-raised-button
              color="primary"
              class="primary-button">
              {{ 'transferReasonsCreateLabels.save' | translate }}
            </button>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </form>
  </div>
</div>

<div [ngClass]="{'vertical-design': !warehouseCreate, 'horizontal-design': warehouseCreate}">
  <div class="location-component-container">
    <div class="map-container">
      <input
        id="search"
        #search
        type="text"
        class="form-control"
        (keydown.enter)="$event.preventDefault()"
        placeholder="{{ 'dynamicLocationMapTags.searchLocationPlaceholder' | translate }}"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off">
        <agm-map
          id="map"
          [latitude]="latitude"
          [longitude]="longitude"
          [zoom]="zoom"
          [zoomControl]="true"
          (mapClick)="mapClickedOrDragEnd($event)">
          <agm-marker
            [latitude]="latitude"
            [longitude]="longitude"
            [markerDraggable]="draggable"
            (dragEnd)="mapClickedOrDragEnd($event)">
          </agm-marker>
        </agm-map>
    </div>
    <br>

    <div *ngIf="warehouseCreate" class="warehouse-form">
      <form [formGroup]="locationFormGroup">
        <mat-grid-list cols="2" rowHeight="1:.39">
          <mat-grid-tile colspan="2" rowspan="1">
            <mat-form-field class="full-input">
              <input
                matInput
                minlength="3"
                maxlength="50"
                placeholder="{{ 'dynamicLocationMapTags.warehouseName' | translate }}"
                formControlName="name"
                [required]="isRequired">
              <mat-error *ngIf="locationFormGroup.controls['name'].hasError('required')">
                {{ 'dynamicLocationMapTags.nameIs' | translate }} <strong>
                {{ 'dynamicLocationMapTags.required' | translate }}{{ 'dynamicLocationMapTags.suffixO' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['name'].hasError('minlength')">
                <strong>{{ 'dynamicLocationMapTags.minLengthName' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['name'].hasError('alreadyTaken')">
                {{ 'dynamicLocationMapTags.alreadyTakenName' | translate }}
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <input
                matInput
                minlength="2"
                maxlength="50"
                placeholder="{{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.provincePlaceholder' | translate) : ('dynamicLocationMapTags.statePlaceholder' | translate) }}"
                formControlName="state"
                [required]="isRequired">
              <mat-error *ngIf="locationFormGroup.controls['state'].hasError('required')">
                {{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.provinceIs' | translate ) : ('dynamicLocationMapTags.stateIs' | translate) }} <strong>
                {{ 'dynamicLocationMapTags.required' | translate }}{{ isEcuadorDefaultLocation ? ( 'dynamicLocationMapTags.suffixA' | translate ) : ( 'dynamicLocationMapTags.suffixO' | translate ) }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['state'].hasError('minlength')">
                <strong>{{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.minLengthProvince' | translate) : ('dynamicLocationMapTags.minLengthState' | translate) }}</strong>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <input
                matInput
                minlength="3"
                [maxlength]="municipalityLength"
                placeholder="{{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.cantonPlaceholder' | translate) : ('dynamicLocationMapTags.municipalityPlaceholder' | translate) }}"
                formControlName="municipality"
                [required]="isRequired">
              <mat-error *ngIf="locationFormGroup.controls['municipality'].hasError('required')">
                {{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.cantonIs' | translate) : ('dynamicLocationMapTags.municipalityIs' | translate) }} <strong>
                {{ 'dynamicLocationMapTags.required' | translate }}{{ 'dynamicLocationMapTags.suffixO' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['municipality'].hasError('minlength')">
                <strong>{{ isEcuadorDefaultLocation ? ( 'dynamicLocationMapTags.minLengthCanton' | translate ) : ( 'dynamicLocationMapTags.minLengthMunicipality' | translate ) }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['municipality'].hasError('maxlength')">
                <strong>{{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.maxLengthCanton' | translate) : ('dynamicLocationMapTags.maxLengthMunicipality' | translate) }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['municipality'].hasError('pattern')">
                <strong>{{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.invalidCanton' | translate) : ('dynamicLocationMapTags.invalidMunicipality' | translate) }}</strong>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <input
                matInput
                minlength="3"
                maxlength="50"
                placeholder="{{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.parishPlaceholder' | translate) : ('dynamicLocationMapTags.settlementPlaceholder' | translate) }}"
                formControlName="settlement"
                [required]="isRequired">
              <mat-error *ngIf="locationFormGroup.controls['settlement'].hasError('required')">
                {{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.parishIs' | translate) : ('dynamicLocationMapTags.settlementIs' | translate) }} <strong>
                {{ 'dynamicLocationMapTags.required' | translate }}{{ 'dynamicLocationMapTags.suffixA' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['settlement'].hasError('minlength')">
                <strong>{{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.minLengthParochial' | translate) : ('dynamicLocationMapTags.minLengthSettlement' | translate) }}</strong>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <input
                (keypress)="onKeyPressCode($event)"
                matInput
                step="10000"
                min="0"
                max="9999999999"
                maxlength="postalCodeMaxLength"
                placeholder="{{ 'dynamicLocationMapTags.postalCodePlaceholder' | translate }}"
                formControlName="postalCode"
                pattern="^\d{4,10}$"
                [required]="isRequired">
              <mat-error *ngIf="locationFormGroup.controls['postalCode'].hasError('required')">
                {{ 'dynamicLocationMapTags.postalCodeIs' | translate }} <strong>
                {{ 'dynamicLocationMapTags.required'| translate }}{{ 'dynamicLocationMapTags.suffixO' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['postalCode'].hasError('minlength')">
                <strong>{{ 'dynamicLocationMapTags.minLengthPostalCode' | translate }}</strong>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" rowspan="1">
            <mat-form-field class="full-input">
              <input
                matInput
                minlength="3"
                maxlength="100"
                placeholder="{{ 'dynamicLocationMapTags.addressPlaceholder' | translate }}"
                formControlName="address"
                [required]="isRequired">
              <mat-error *ngIf="locationFormGroup.controls['address'].hasError('required')">
                {{ 'dynamicLocationMapTags.addressIs' | translate }} <strong>
                {{ 'dynamicLocationMapTags.required' | translate }}{{ 'dynamicLocationMapTags.suffixA' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['address'].hasError('minlength')">
                <strong>{{ 'dynamicLocationMapTags.minLengthAddress' | translate }}</strong>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </form>
    </div>

    <div *ngIf="!warehouseCreate" class="location-form">
      <form [formGroup]="locationFormGroup">
        <mat-grid-list cols="4" rowHeight="1:.3">
          <mat-grid-tile colspan="2" rowspan="1">
            <mat-form-field>
              <input
                matInput
                placeholder="{{ 'dynamicLocationMapTags.namePlaceholder' | translate }}"
                formControlName="name"
                required>
              <mat-error *ngIf="locationFormGroup.hasError('required')">
                {{ 'dynamicLocationMapTags.nameIs' | translate }} <strong>
                {{ 'dynamicLocationMapTags.required' | translate }}{{ 'dynamicLocationMapTags.suffixO' | translate }}</strong>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" rowspan="1">
            <mat-form-field>
              <input
                matInput
                placeholder="{{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.provincePlaceholder' | translate) : ('dynamicLocationMapTags.statePlaceholder' | translate) }}"
                formControlName="state"
                required>
              <mat-error *ngIf="locationFormGroup.hasError('required')">
                {{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.provinceIs') : ('dynamicLocationMapTags.stateIs' | translate) }} <strong>
                {{ 'dynamicLocationMapTags.required' | translate }}{{ 'dynamicLocationMapTags.suffixO' | translate }}</strong>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <input
                matInput
                placeholder="{{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.cantonPlaceholder' | translate) : ('dynamicLocationMapTags.municipalityPlaceholder' | translate) }}"
                formControlName="municipality"
                required>
              <mat-error *ngIf="locationFormGroup.hasError('required')">
                {{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.cantonIs' | translate) : ('dynamicLocationMapTags.municipalityIs' | translate) }} <strong>
                {{ 'dynamicLocationMapTags.required' | translate }}{{ 'dynamicLocationMapTags.suffixO' | translate }}</strong>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <input
                matInput
                placeholder="{{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.parishPlaceholder' | translate) : ('dynamicLocationMapTags.settlementPlaceholder' | translate) }}"
                formControlName="settlement"
                required>
              <mat-error *ngIf="locationFormGroup.hasError('required')">
                {{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.parishIs' | translate) : ('dynamicLocationMapTags.settlementIs' | translate) }} <strong>
                {{ 'dynamicLocationMapTags.required' | translate }}{{ 'dynamicLocationMapTags.suffixA' | translate }}</strong>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <input
                matInput
                step="10000"
                min="0"
                max="999999"
                maxlength="postalCodeMaxLength"
                placeholder="{{ 'dynamicLocationMapTags.postalCodePlaceholder' | translate }}"
                formControlName="postalCode"
                required>
              <mat-error *ngIf="locationFormGroup.hasError('required')">
                {{ 'dynamicLocationMapTags.postalCodeIs' | translate }} <strong>
                {{ 'dynamicLocationMapTags.required' | translate }}{{ 'dynamicLocationMapTags.suffixO' | translate }}</strong>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <input
                matInput
                placeholder="{{ 'dynamicLocationMapTags.addressPlaceholder' | translate }}"
                formControlName="address"
                required>
              <mat-error *ngIf="locationFormGroup.hasError('required')">
                {{ 'dynamicLocationMapTags.addressIs' | translate }} <strong>
                {{ 'dynamicLocationMapTags.required' | translate }}{{ 'dynamicLocationMapTags.suffixA' | translate }}</strong>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile *ngIf="!rfcIsRequired">
            <mat-form-field>
              <input
              id="locationRFCInput"
              matInput
              formControlName="locationRFC"
              placeholder="{{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.rucPlaceholder' | translate) : ('dynamicLocationMapTags.rfcPlaceholder' | translate) }}"
              autocomplete="off">
              <mat-error *ngIf="locationFormGroup.controls['locationRFC'].hasError('pattern')">
                <strong>{{ isEcuadorDefaultLocation ?  ('dynamicLocationMapTags.rucValidFormat' | translate) : ( 'dynamicLocationMapTags.rfcValidFormat' | translate) }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['locationRFC'].hasError('minlength')">
                <strong>{{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.minLengthRucLabel' | translate) : ('dynamicLocationMapTags.minLengthRfcLabel' | translate) }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['locationRFC'].hasError('maxlength')">
                <strong>{{ isEcuadorDefaultLocation ? ('dynamicLocationMapTags.maxLengthRucLabel' | translate) : ('dynamicLocationMapTags.maxLengthRfcLabel' | translate) }}</strong>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile *ngIf="rfcIsRequired">
            <mat-form-field>
              <input
              id="locationRFCInput"
              matInput
              required
              formControlName="locationRFC"
              placeholder="RFC"
              autocomplete="off">
              <mat-error *ngIf="locationFormGroup.controls['locationRFC'].hasError('required')">
                <strong>{{ 'dynamicLocationMapTags.requiredRfc' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['locationRFC'].hasError('pattern')">
                <strong>{{ 'dynamicLocationMapTags.rfcValidFormat' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['locationRFC'].hasError('minlength')">
                <strong>{{ 'dynamicLocationMapTags.minLengthRfcLabel' | translate }}</strong>
              </mat-error>
              <mat-error *ngIf="locationFormGroup.controls['locationRFC'].hasError('maxlength')">
                <strong>{{ 'dynamicLocationMapTags.maxLengthRfcLabel' | translate }}</strong>
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile *ngIf="showExtraFields">
            <mat-form-field>
              <input
                id="phoneInput"
                matInput
                maxlength="13"
                pattern="^\d{10,13}$"
                placeholder="{{ 'dynamicLocationMapTags.phonePlaceholder' | translate }}"
                formControlName="phone">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile *ngIf="showExtraFields">
            <mat-form-field>
              <input
                id="reponsableInput"
                matInput
                maxlength="30"
                placeholder="{{ 'dynamicLocationMapTags.responsablePlaceholder' | translate }}"
                formControlName="responsable">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile *ngIf="showExtraFields">
            <mat-form-field>
              <input
                id="locationDescriptionInput"
                matInput
                maxlength="100"
                placeholder="{{ 'dynamicLocationMapTags.locationDescriptionPlaceholder' | translate }}"
                formControlName="locationDescription">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <button
              id="submitLocationButton"
              mat-raised-button
              [disabled]="!locationFormGroup.valid"
              class="primary-button"
              (click)="onSubmit()">
              {{ buttonConfirmLabel }}
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </form>
    </div>
  </div>
</div>
